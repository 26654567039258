import React, { useMemo, useState } from 'react';
import { SunIcon } from '@kit/ui/icons/Sun';
import { BatteryCharging, Home } from 'react-feather';
import { ChartPeriod, chartPeriodDuration, chartPeriodName, getObservedInterval } from '@hooks/systems';
import { TransmissionTowerIcon } from '@kit/ui/icons/TransmissionTower';
import { SolarPowerIcon } from '@kit/ui/icons/SolarPower';
import { useSystemHealthChart } from '@hooks/systems/useSystemHealthChart';
import { formatEnergy, gapfillPoints } from '@features/SystemPortfolio/utils';
import { EnergyChart } from '@features/SystemPortfolio/components/EnergyChart';
import { DateTime, Interval } from 'luxon';
import { Legend } from 'recharts';
import { ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { SyncFrequency, System } from '@generated/types/graphql';
import { capitalize } from 'lodash';
import { Switch, SwitchVariant } from '@kit/ui/Switch';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronLeftIcon } from '@kit/ui/icons/ChevronLeft';
import { Select } from '@kit/ui/Select';
import { ChartLegendPointIcon } from '@kit/ui/icons/ChartLegendPoint';
import homeImg from './home.png';
import {
  AnnotationBorderRect,
  AnnotationRect,
  AnnotationRectInner,
  AnnotationSubtitle,
  AnnotationTitle,
  Chart,
  ChartContainer,
  ChartLegend,
  ChartLegendLabel,
  ChartLegendLabels,
  ChartTitle,
  ConsumedAnnotation,
  ConsumedLine,
  HomeImage,
  HomeImageAndStats,
  NextSync,
  PeriodAndArrows,
  PeriodControls,
  RectBottomArrow,
  RectLeftArrow,
  RectRightArrow,
  RectTopArrow,
  SolarAnnotation,
  SolarLine,
  SystemSyncDate
} from './styled';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';

const PERIOD_OPTIONS = Object.values(ChartPeriod).map((period) => ({ value: period, label: chartPeriodName[period] }));

const DEFAULT_PERIOD = PERIOD_OPTIONS.find(({ value }) => value === ChartPeriod.day);

type Props = {
  system: System;
};

export const SystemHealthWidget = ({ system }: Props) => {
  const earliestDate = DateTime.fromISO(system.operationalAt || system.createdAt);

  const [selectedPeriod, setSelectedPeriod] = useState<(typeof PERIOD_OPTIONS)[number]>(DEFAULT_PERIOD);

  const period = selectedPeriod.value;

  const [anchorTime, setAnchorTime] = useState<DateTime>(DateTime.now().minus({ days: 1 }));

  const observedInterval = useMemo(() => getObservedInterval(anchorTime, period), [anchorTime, period]);

  const hasPrevPeriod = useMemo(
    () => observedInterval && +observedInterval.start >= +earliestDate,
    [observedInterval, earliestDate]
  );

  const hasNextPeriod = useMemo(() => observedInterval && +observedInterval.end <= +DateTime.now(), [observedInterval]);

  const goPrevInterval = () => {
    setAnchorTime(anchorTime.minus(chartPeriodDuration[period]));
  };

  const goNextInterval = () => {
    setAnchorTime(anchorTime.plus(chartPeriodDuration[period]));
  };

  const [showPower, setShowPower] = useState(false);

  const {
    data: { totalProduction, totalConsumption, totalExport, totalImport, totalCharge, totalDischarge, energy, power }
  } = useSystemHealthChart(system?.uuid, period, observedInterval?.start, observedInterval?.end);

  const gapfilledEnergy = useMemo(
    () => gapfillPoints(energy, period, observedInterval),
    [energy, period, observedInterval]
  );

  const gapfilledPower = useMemo(
    () => gapfillPoints(power, period, observedInterval),
    [power, period, observedInterval]
  );

  const actualInterval = useMemo(
    () =>
      Interval.fromDateTimes(observedInterval?.start || earliestDate, observedInterval?.end.minus(1) || DateTime.now()),
    [observedInterval, earliestDate]
  );

  const lastSyncDate = system.systemLogsBySystemIdConnection?.aggregates?.max?.createdAt;

  if (!system) {
    return null;
  }

  return (
    <Widget size="large" isFullWidth>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#f1aa12">
            <SunIcon size="16px" color="#ffffff" />
          </WidgetIcon>
          System health
        </WidgetTitle>

        <PeriodControls>
          <PeriodAndArrows>
            {actualInterval.toLocaleString({
              day: 'numeric',
              month: 'short',
              year: '2-digit'
            })}
            <IconButton
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={goPrevInterval}
              disabled={!hasPrevPeriod}
            >
              <ChevronLeftIcon size="16px" />
            </IconButton>

            <IconButton
              variant={ButtonVariant.Flat}
              size={ButtonSize.Small}
              onClick={goNextInterval}
              disabled={!hasNextPeriod}
            >
              <ChevronRightIcon size="16px" />
            </IconButton>
          </PeriodAndArrows>
          <Select
            value={selectedPeriod}
            getOptionLabel={(option) => option.label}
            onChange={(e, value) => setSelectedPeriod(value)}
            options={PERIOD_OPTIONS}
            isClearable={false}
          />
        </PeriodControls>
      </WidgetHeader>

      <HomeImageAndStats className="image-container">
        <HomeImage src={homeImg} alt="Solar System" />

        <SolarAnnotation>
          <AnnotationTitle>
            <SolarPowerIcon size="16px" color="#FFBD13" />
            {formatEnergy(totalProduction)}
          </AnnotationTitle>
          <AnnotationSubtitle>solar</AnnotationSubtitle>
        </SolarAnnotation>
        <SolarLine />

        <ConsumedAnnotation>
          <AnnotationTitle>
            <Home size="16px" color="#9C9CAA" />
            {formatEnergy(totalConsumption)}
          </AnnotationTitle>
          <AnnotationSubtitle>consumed</AnnotationSubtitle>
        </ConsumedAnnotation>
        <ConsumedLine />

        <AnnotationBorderRect>
          <RectLeftArrow>
            <ChevronUpIcon size="12px" color="#FFBD13" />
          </RectLeftArrow>
          <RectTopArrow>
            <ChevronRightIcon size="12px" color="#FFBD13" />
          </RectTopArrow>
          <RectRightArrow>
            <ChevronDownIcon size="12px" color="#009A47" />
          </RectRightArrow>
          <RectBottomArrow>
            <ChevronLeftIcon size="12px" color="#009A47" />
          </RectBottomArrow>
        </AnnotationBorderRect>

        <AnnotationRect>
          <AnnotationRectInner>
            <div>
              <AnnotationTitle>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalExport)}
              </AnnotationTitle>
              <AnnotationSubtitle>exported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <TransmissionTowerIcon size="16px" color="#9C9CAA" />
                {formatEnergy(totalImport)}
              </AnnotationTitle>
              <AnnotationSubtitle>imported</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <BatteryCharging size="16px" color="#9C9CAA" />
                {formatEnergy(totalCharge)}
              </AnnotationTitle>
              <AnnotationSubtitle>charged</AnnotationSubtitle>
            </div>
            <div>
              <AnnotationTitle>
                <BatteryCharging size="16px" color="#9C9CAA" />
                {formatEnergy(totalDischarge)}
              </AnnotationTitle>
              <AnnotationSubtitle>discharged</AnnotationSubtitle>
            </div>
          </AnnotationRectInner>
        </AnnotationRect>
      </HomeImageAndStats>

      <SystemSyncDate>
        <div>
          Last sync: {lastSyncDate ? DateTime.fromISO(lastSyncDate).toLocaleString(DateTime.DATETIME_SHORT) : 'never'}
        </div>
        <NextSync>(sync once a {system.integration?.frequency === SyncFrequency.Day ? 'day' : 'hour'})</NextSync>
      </SystemSyncDate>

      <ChartContainer>
        <ChartTitle>
          System production & consumption, {showPower ? 'W' : 'Wh'}
          <Switch
            variant={SwitchVariant.TwoWay}
            label="Power"
            secondLabel="Energy"
            isActive={showPower}
            onChange={(showPower) => setShowPower(showPower)}
          />
        </ChartTitle>

        <Chart>
          <EnergyChart
            points={showPower ? gapfilledPower : gapfilledEnergy}
            period={period}
            enabled={{ production: !showPower, consumption: !showPower, power: showPower }}
          >
            <Legend
              verticalAlign="bottom"
              align="center"
              // eslint-disable-next-line
              content={({ payload }) => (
                <ChartLegend>
                  <ChartLegendLabels>
                    {payload.map(({ value, color }) => (
                      <ChartLegendLabel key={value}>
                        <ChartLegendPointIcon size="16px" color={color} />

                        {capitalize(value)}
                      </ChartLegendLabel>
                    ))}
                  </ChartLegendLabels>
                </ChartLegend>
              )}
            />
          </EnergyChart>
        </Chart>
      </ChartContainer>
    </Widget>
  );
};
