import { useConfirmDeleteModal, useConfirmModal, useModal } from '@common/PromiseModal';
import { useFileMutation } from '@hooks/useFiles';
import { MenuItem } from '@kit/components/ContextMenu';
import { Archive, Download, Edit3, Eye, EyeOff } from 'react-feather';
import React, { useMemo } from 'react';
import { MoveToFolderIcon } from '@kit/ui/icons/MoveToFolder';
import { hasEntityAccessNew } from '@utils/roles';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { ExportIcon } from '@kit/ui/icons/Export';
import { Export } from '@components/Project/DataBank/Export';
import { RecordType } from '@types';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled } from '@state/selectors';
import { useShareDocWithPortal } from '@hooks/documents/useShareDocWithPortal';
import { RenameDoc } from './components/RenameDoc';
import { MoveDoc } from './components/MoveDoc';
import { extractEntityIdFromUrl } from '../../../utils';
import { DrawerEntity } from '../../../contexts/DrawersContext';

type Doc = {
  id: number;
  name: string;
  projectId: number;
  form: {
    id: number;
  };
  type: string;
  isArchived: boolean;
  privilege: string;
  sharedWithPortal: boolean;
};

type Props = {
  doc?: Doc;
  recordType: RecordType;
  onDeleted?: () => void;
};

const EMPTY: MenuItem[] = [];

export const useDocContextMenuItems = ({ doc, recordType, onDeleted }: Props) => {
  const { openModal } = useModal();
  const confirm = useConfirmModal();
  const confirmRemove = useConfirmDeleteModal();
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);
  const { mutateAsync: shareDocWithPortal } = useShareDocWithPortal();
  const {
    update: { mutateAsync: update },
    remove: { mutateAsync: remove },
    download: { mutateAsync: downloadFile }
  } = useFileMutation();

  return useMemo<(MenuItem & { isVisible?: boolean })[]>(() => {
    if (!doc) {
      return EMPTY;
    }

    const isCommentAttachment = doc.type === 'file' && !doc.projectId;

    return [
      {
        icon: <MoveToFolderIcon size="16px" color="#98A9BC" />,
        title: 'Move',
        onClick: () => {
          openModal<void>(({ onClose }) => <MoveDoc doc={doc} onClose={onClose} />, {
            title: 'Move document'
          });
        },
        isVisible: !isCommentAttachment && doc.type !== 'public' && !doc.isArchived && hasEntityAccessNew(doc, 'edit')
      },
      {
        icon: <Eye size="16px" color="#98A9BC" />,
        title: 'Share with Portal',
        onClick: () => {
          shareDocWithPortal({
            docId: doc.id,
            sharedWithPortal: true,
            isPropertyFile: !doc.projectId,
            recordId: doc.projectId,
            fileId: extractEntityIdFromUrl(window.location.pathname, DrawerEntity.DOC)
          });
        },
        isVisible: isPortalEnabled && !doc.sharedWithPortal
      },
      {
        icon: <EyeOff size="16px" color="#98A9BC" />,
        title: 'Hide from Portal',
        onClick: () => {
          shareDocWithPortal({
            docId: doc.id,
            recordId: doc.projectId,
            sharedWithPortal: false
          });
        },
        isVisible: doc.projectId && isPortalEnabled && doc.sharedWithPortal
      },
      {
        icon: <ExportIcon size="16px" color="#98A9BC" />,
        title: 'Export',
        onClick: () => {
          openModal<void>(({ onClose }) => <Export recordType={recordType} formId={doc.form.id} onClose={onClose} />, {
            title: 'Export form'
          });
        },
        isVisible: !doc.isArchived && doc.form
      },
      {
        icon: <Download size="16px" color="#98A9BC" />,
        title: 'Download',
        onClick: () => {
          downloadFile({
            id: doc.form?.id ?? doc.id,
            type: doc.type
          });
        }
      },
      {
        icon: <Edit3 size="16px" color="#98A9BC" />,
        title: 'Rename',
        onClick: () => {
          openModal<void>(({ onClose }) => <RenameDoc doc={doc} onClose={onClose} />, {
            title: 'Rename Document'
          });
        },
        isVisible: !doc.isArchived && hasEntityAccessNew(doc, 'edit')
      },
      {
        icon: <Archive size="16px" color="#98A9BC" />,
        title: 'Archive',
        onClick: async () => {
          if (
            await confirm({ confirmText: 'Are you sure you want to archive this file?', confirmButtonText: 'Archive' })
          ) {
            await update({
              fileId: doc.id,
              isArchived: true
            });
          }
        },
        isVisible: !isCommentAttachment && doc.type !== 'public' && !doc.isArchived && hasEntityAccessNew(doc, 'edit')
      },
      {
        icon: <UnarchiveIcon size="16px" color="#98A9BC" />,
        title: 'Restore',
        onClick: () => {
          update({
            fileId: doc.id,
            isArchived: false
          });
        },
        isVisible: doc.type !== 'public' && doc.isArchived && hasEntityAccessNew(doc, 'edit')
      },
      {
        icon: <TrashIcon size="16px" color="#ED4947" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmRemove('Are you sure you want to delete this file?')) {
            await remove({
              fileId: doc.id,
              projectId: doc.projectId
            });

            onDeleted?.();
          }
        },
        isVisible: !isCommentAttachment && doc.type !== 'public' && doc.isArchived && hasEntityAccessNew(doc, 'delete')
      }
    ].filter((item) => typeof item.isVisible === 'undefined' || item.isVisible);
  }, [
    doc,
    downloadFile,
    update,
    remove,
    confirm,
    confirmRemove,
    openModal,
    recordType,
    onDeleted,
    isPortalEnabled,
    shareDocWithPortal
  ]);
};
